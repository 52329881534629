import { createStore as createZustandStore } from 'zustand/vanilla';
import { RECENTLY_PLAYED_STORAGE_KEY, MAX_RECENTLY_PLAYED_ITEMS } from '../utils/constants';

export type StoreData = {
  isMediaMuted: boolean;
  isModalOpen: boolean;
  activeCardId: Symbol | null;
  recentlyPlayed: string[];
  debug: {
    disableVideos: boolean | undefined;
    showFeedbackSticker: boolean | undefined;
  };
};

export type StoreActions = {
  setMediaMuted: (isMediaMuted: boolean) => void;
  setModalOpen: (isModalOpen: boolean) => void;
  setActiveCardId: (id: Symbol | null) => void;
  loadRecentlyPlayed: () => void;
  addRecentlyPlayed: (id: string) => void;
  clearRecentlyPlayed: () => void;
  setDebug: (debug: Partial<StoreData['debug']>) => void;
};

export type StoreState = StoreData & StoreActions;

export const defaultInitData: StoreData = {
  isMediaMuted: false,
  isModalOpen: false,
  activeCardId: null,
  recentlyPlayed: [],
  debug: {
    disableVideos: undefined,
    showFeedbackSticker: undefined,
  },
};

export const createStore = (initData: StoreData = defaultInitData) => {
  let recentlyPlayedAddTimeout: number = -1;
  return createZustandStore<StoreState>()((set) => ({
    ...initData,
    setMediaMuted: (isMediaMuted) => {
      set({ isMediaMuted });
    },
    setModalOpen: (isModalOpen) => {
      set({ isModalOpen });
    },
    setActiveCardId: (activeCardId) => {
      set({ activeCardId });
    },
    loadRecentlyPlayed: () => {
      window.clearTimeout(recentlyPlayedAddTimeout);
      const recentlyPlayed = JSON.parse(localStorage.getItem(RECENTLY_PLAYED_STORAGE_KEY) || '[]');
      set({ recentlyPlayed });
    },
    addRecentlyPlayed: (id) => {
      window.clearTimeout(recentlyPlayedAddTimeout);

      // Parse the recently played list from local storage.
      let recentlyPlayed: string[] = JSON.parse(
        localStorage.getItem(RECENTLY_PLAYED_STORAGE_KEY) || '[]',
      );

      // Remove the ID if it already exists in the list.
      recentlyPlayed = recentlyPlayed.filter((i) => i !== id);

      // Add the ID to the beginning of the list.
      recentlyPlayed.unshift(id);

      // Keep only the first X items.
      recentlyPlayed = recentlyPlayed.slice(0, MAX_RECENTLY_PLAYED_ITEMS);

      // Update the local storage immediately.
      localStorage.setItem(RECENTLY_PLAYED_STORAGE_KEY, JSON.stringify(recentlyPlayed));

      // However, update the state after a short delay to prevent the UI from
      // re-rendering immediately. If `addRecentlyPlayed` is called in an
      // anchor tag's `onClick` handler (e.g. recently played card) the UI might
      // re-render before the click event is fully processed, causing the
      // browser to navigate to a wrong URL.
      recentlyPlayedAddTimeout = window.setTimeout(() => {
        set({ recentlyPlayed });
      }, 0);
    },
    clearRecentlyPlayed: () => {
      window.clearTimeout(recentlyPlayedAddTimeout);
      localStorage.setItem(RECENTLY_PLAYED_STORAGE_KEY, JSON.stringify([]));
      set({ recentlyPlayed: [] });
    },
    setDebug: (debug) => {
      set((state) => {
        return {
          debug: {
            ...state.debug,
            ...debug,
          },
        };
      });
    },
  }));
};
