export const IS_RAY = (() => {
  try {
    // @ts-ignore
    return window.navigator.userAgentData.brands.some(({ brand }) => brand === 'Ray');
  } catch (e) {
    return false;
  }
})();

export const RECENTLY_PLAYED_STORAGE_KEY = 'recentlyPlayed';
export const STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED = 'player-questionnaire-dismissed';
export const STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_DATE =
  'player-questionnaire-dismissed-date';
export const STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_COUNT =
  'player-questionnaire-dismissed-count';
export const MAX_RECENTLY_PLAYED_ITEMS = 10;
export const SIDE_PANEL_ANCHOR_CLASS = 'side-panel-anchor';
